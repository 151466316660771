.project-card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    min-height: 400px;
    max-height: 600px;
    margin-bottom: 2em;
    border-radius: 25px;
    overflow: hidden;
    padding-top: 56.25%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);

    &-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 25px;
        transition: transform 0.3s ease-in-out;
    }

    &:hover {
        .project-card-image {
            transform: scale(1.1);
        }

        .project-card-content {
            background-color: rgba(0, 0, 0, 0.7); // on hover
            opacity: 1; // on hover
        }
    }

    &-content {
        position: relative;
        background-color: rgba(0, 0, 0, 0); // initial state
        color: white;
        padding: 20px;
        width: 100%;
        opacity: 0; // initial state
        visibility: visible; // change to visible
        z-index: 1;
        transition: opacity 0.5s ease-in-out, background-color 0.5s ease-in-out; // transition background-color
    }

    &-title {
        font: {
            size: 3rem;
            weight: 200
        }
        letter-spacing: 2px;

        @media (max-width: 1024px) {
            font: {
                size: 2rem;
            }
        }
    }

    &-description {
        font: {
            size: 1.2rem;
        }
        margin-bottom: 1em;
    }

    &-subtitle {
        font: {
            size: 1.5rem;
            weight: bolder
        }
        margin-bottom: 0.5em;
    }
}

.learn-more-button {
    font: {
        family: 'Raleway', sans-serif;
        size: 1.2rem;
    }
    width: 200px;
    padding: 5px;
    height: 50px;
    margin-top: 1.2rem;
    letter-spacing: 0.8px;
    border: none;
    border-radius: 5px;
    background: linear-gradient(to right, white 50%, var(--secondary-color-lightblue) 50%);
    background-size: 200% 100%;
    transition: background-position 0.3s ease;

    &:hover {
        background-position: -100% 0;
        color: #FFFFFF;
    }

    @media (max-width: 1024px) {
        display: none;
    }
}

.mobile-hide {
    @media (max-width: 1024px) {
        display: none;
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
