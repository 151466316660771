@import './Shared/theme.scss';
@import './Shared/utilities.scss';

*{
  font: {
    family: 'Raleway', sans-serif;
  }
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

.bg-custom-dark{
  background-color: var(--main-color-dark);
  color: var(--main-color-light);
}

.bg-custom-light{
  background-color: var(--main-color-light);
  color: var(--main-color-dark);
}

.bg-custom-transparent{
  background-color: transparent;
}

.logo {
  @include respond-to(tablet) {
      width: 400px;
  }

  @include respond-to(mobile) {
      width: 200px;
  }
}

.page-section {
  position: relative;
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  @include flex-center;
}

// Text containers
.text-container-lg {
  @include padding-x-20;
  @include padding-y-10;

  h1 {
      margin-bottom: 30px;
      font: {
        size: 4rem;
      }

      @include respond-to(tablet){
      font: {
          size: 2.5rem;
      }
      }

      @include respond-to(mobile){
      font: {
          size: 2.5rem;
      }
      }
  }

  h2 {
      margin-bottom: 25px;
      font: {
        size: 2rem;
      }

      @include respond-to(tablet){
        font: {
          size: 1.5rem;
        }
      }
  }

  div {
      margin-bottom: 30px;
      font: {
      size: 1.2rem;
      }

      @include respond-to(tablet){
        font: {
          size: 1rem;
        }
      }
  }

  @include respond-to(large-tablet){
      @include padding-x-10;
      @include padding-y-0;
  }

  @include respond-to(mobile){
      @include p-padding-x-30;
      @include p-padding-y-50;
  }
}

// Text containers
.text-container-md {
  @include padding-x-10;
  @include padding-y-5;

  h1 {
      margin-bottom: 30px;
      font: {
      size: 3rem;
      }
      color: var(--secondary-color-grey);

      @include respond-to(tablet){
      font: {
          size: 2.5rem;
      }
      }

      @include respond-to(mobile){
      font: {
          size: 2rem;
      }
      }
  }

  h2 {
      margin-bottom: 25px;
      color: var(--secondary-color-grey);
      font: {
      size: 2.5rem;
      }

      @include respond-to(tablet){
      font: {
          size: 2rem;
      }
      }

      @include respond-to(mobile){
      font: {
          size: 1.5rem;
      }
      }
  }

  div {
      margin-bottom: 30px;
      font: {
      size: 1.2rem;
      }
  }

  @include respond-to(large-tablet){
      @include padding-x-10;
      @include padding-y-0;
  }

  @include respond-to(mobile){
      @include p-padding-x-30;
      @include p-padding-y-50;
  }
}

// Text containers
.text-container-sm {
  @include padding-x-5;
  @include padding-y-5;

  h1 {
      margin-bottom: 30px;
      font: {
      size: 3rem;
      }

      @include respond-to(tablet){
      font: {
          size: 2.5rem;
      }
      }

      @include respond-to(mobile){
      font: {
          size: 2rem;
      }
      }
  }

  h2 {
      margin-bottom: 25px;
      font: {
      size: 2.5rem;
      }

      @include respond-to(tablet){
      font: {
          size: 2rem;
      }
      }

      @include respond-to(mobile){
      font: {
          size: 1.5rem;
      }
      }
  }

  h4 {
    margin-bottom: 25px;
    color: var(--main-color-dark);
    font: {
    size: 2.5rem;
    }

    @include respond-to(tablet){
    font: {
        size: 2rem;
    }
    }

    @include respond-to(mobile){
    font: {
        size: 1.5rem;
    }
    }
  }

  div {
      margin-bottom: 30px;
      font: {
      size: 1.2rem;
      }
  }


  @include respond-to(mobile){
      @include p-padding-x-30;
      @include p-padding-y-50;
  }
}

// Page containers
.page-container {
  width: auto;
  @include flex-center;
}

.margin-auto {
  margin: 0 auto;
}

// Section containers
.section-container {
  padding: 5rem 1rem;
  box-sizing: border-box;
  margin: 0 200px;

  @include respond-to(tablet){
      padding: 3rem 1rem;
      margin: 0 100px;
  }

  @include respond-to(mobile){
      padding: 0;
      margin: 0;
  }
}


