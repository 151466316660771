// IMPORTS
@import './../../../../Styles/Shared/utilities.scss';
@import './../../../../Styles/Shared/theme.scss';

.lower-headline {
  font-weight: 100;
  font-size: 3rem;
  letter-spacing: 0.8px;
}

.upper-headline {
  font-weight: bold;
  font-size: 6rem;
  letter-spacing: 0.8px;
  text-align: left;
}

.single-section-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 85vh;
  padding: 50px 0px;
  overflow: hidden;

  span {
    display: block;
    opacity: 0;

  @include respond-to(tablet)
  {
    height: 35vh;
  }

  @include respond-to(mobile)
  {
    height: 40vh;
  }
  
}

  h1 {
    color: var(--main-color-light);
    margin: 0 100px;
    font: {
      size: 6rem;
      weight: 600;
    }

    @include respond-to(large-tablet){
      margin: 0 80px;
      font: {
        size: 5rem;
        weight: 600;
      }
    }

    @include respond-to(tablet){
      margin: 0 50px;
      font: {
        size: 4rem;
        weight: 600;
      }
    }

    @include respond-to(mobile){
      margin: 0 10px;
      font: {
        size: 2.3rem;
        weight: 600;
      }
    }
  }
}
  
.full {
  height: 100vh;

  @include respond-to(tablet){
    height: 55vh;
  }
}
  
