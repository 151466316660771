.privacy-policy {
    margin: 50px;

    h1 {
        font-size: 2rem;
        margin-bottom: 2.75rem;
    }

    .section {
        margin-bottom: 2rem;
        
        h2 {
            margin-bottom: 25px;
            text-decoration: solid 2px black;
            font-size: 1.5rem;
            margin-bottom: 10px;
        }

        p {
            font-size: 16px;
            line-height: 1.5;
        }
    }
}
