@import '../../Styles/Shared/utilities.scss';
@import '../../Styles/Shared/theme.scss';

/*
---------------------------
CUSTOM CSS FOR HomePage.tsx
---------------------------
*/

// STATS SECTION CSS (the circles)
.stats-section {
  padding-top: 1.5rem;
  padding-bottom: 12.5rem;
  
  @include respond-to(tablet){
    padding-top: 3rem;
  }
}

.circles-container {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  @include flex-center;

  @include respond-to(mobile) { 
    flex-direction: column;
  }
}

.circle-wrapper {
  margin: 50px 75px;
  max-width: calc(50% - 150px);

  @media (min-width: 1201px) and (max-width: 1824px)
  {
    margin: 50px 25px;
  }

  @media (min-width: 901px) and (max-width: 1200px) {
      max-width: calc(50% - 20px);
      max-width: 100%;
  }

  @media (max-width: 768px) { 
      margin: 50px 25px; 
      max-width: 100%;
  }
}

// PROJECT BUTTON CSS (with animations)
.homepage-btn {
    color: var(--main-color-dark);
    transition: all 0.3s;
    position: relative;
    line-height: 50px;
    height: 50px;
    text-align: center;
    width: 250px;
    cursor: pointer;
  }
  .homepage-btn span {
    font-size: 1.4rem;
    transition: all 0.3s;
  }

  .homepage-btn-arrow{
    margin-left: 2px;
  }
    
  .homepage-btn::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    transition: all 0.3s;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-top-style: solid;
    border-bottom-style: solid;
    border-top-color: var(--main-color-dark);
    border-bottom-color: var(--main-color-dark);
    transform: scale(0.1, 1);
  }
  .homepage-btn:hover span {
    letter-spacing: 2px;
  }
  .homepage-btn:hover::before {
    opacity: 1; 
    transform: scale(1, 1); 
  }
  .homepage-btn::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
    background-color: rgba(255,255,255,0.1);
  }
  .homepage-btn:hover::after {
    opacity: 0; 
    transform: scale(0.1, 1);
}


  