// IMPORTS
@import './../../../Styles/Shared/utilities.scss';
@import './../../../Styles/Shared/theme.scss';

.slider-container{
    position: relative;
    height: 100%;
    border-radius: 0px;
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.2);

    @media(max-width: 768px) {
      border-radius: 0px;
    }
}

.slide{
    width: auto;
    height: 100%;
    background-position: center;
    background-size: cover
}

.slide-container{
  display: flex;
  height: 100%;
  transition: transform ease-out 0.3s;
}

.image-slider-container {
  width: auto;
  height: 100%;
  position: relative;
  
}

.slide-container-overflow-protection{
  overflow: hidden;
  height: 100%;
  border-radius: 0px;

  @media(max-width: 768px) {
    border-radius: 0px;
  }
}

.slider-arrow-left-container,
.slider-arrow-right-container {
  display: flex;
  position: absolute;
  top: 50%;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100px;
  height: 100px;
  transform: translate(0, -50%);
  background-color: var(--main-color-light);
  border-radius: 50%;
  color: var(--main-color-dark);
  z-index: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  @media (max-width: 1024px) {
    font-size: 3rem;
    height: 75px;
    width: 75px;
  }

  @media (max-width: 768px) {
      font-size: 3rem;
  }
}

.arrow-right,
.arrow-left{
  position: relative;
  height: auto;
  width: 100%;
  font-size: 3rem;
  transition: all 0.2s ease-in-out;

  @include respond-to(tablet){
    font-size: 2rem;
  }
}

.arrow-right{
  right: -1px;
  top: -2px;
}

.arrow-left{
  left: -2px;
  bottom: 2px;
}


.slider-arrow-left-container:hover,
.slider-arrow-right-container:hover {
  color: var(--secondary-color-lightblue);
  transform: translate(0, -50%) scale(1.1);

  .arrow-left,
  .arrow-right {
    transform: scale(1.2)
  }
}

.slider-arrow-left-container {
  left: 50px;

  @media (max-width: 1024px) {
    left: 50px;
  }

  @media (max-width: 768px) {
    left: 10px;
  }
}

.slider-arrow-right-container {
  right: 50px;

  @media (max-width: 1024px) {
    right: 50px;
  }

  @media (max-width: 768px) {
    right: 10px;
  }

}

.dot-index-container{
    display: flex;
    justify-content: center;
}

.dot-index{
    margin: 0 3px;
    cursor: pointer;
    font-size: 20px;
}

