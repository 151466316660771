.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(700px, 1fr));
  grid-gap: 1em;
  max-width: 100%;
  margin: 0 auto;
  padding: 1em;
}
  
@media (max-width: 768px) {
    .card-grid {
      grid-template-columns: 1fr;
    }
}

.line-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px; 
  margin: 5% 10% 1% 10%
}

.line {
  flex-grow: 1;
  height: 2px;  
  background-color: var(--main-color-dark);
  transform-origin: center;
  border-radius: 15px;  /* Rounded ends */
  opacity: 0.2;
  animation: lineAnimation 1s ease-out forwards;
}

.hidden-container{
  margin: 0 10%;
}

/* Animation keyframes */
@keyframes lineAnimation {
  0% {
    transform: scaleX(0);  /* The line starts from zero width */
  }
  100% {
    transform: scaleX(1);  /* The line expands to full width */
  }
}