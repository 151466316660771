//VARIABLES
$main-color-dark: var(--maincolorDark);
$main-color-light: var(--main-color-light);


//VERTICAL ANGLED BACKGROUND
.v-angled-bg-text-container,
.v-angled-bg-text-rotated-block {
  display:block;
}

.v-angled-bg-text-container {
  background: var(--main-color-dark);
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.v-angled-bg-text-rotated-block {
  position: absolute;
  z-index: 1;
  width: 100%;
  min-height: 150%;
  background: #FFFFFF;
  transform: rotate(-15deg);
  left: -45%;
}

.bg-text-left-container{
  margin: 150px 100px;

  h4{
    font-size: 4rem;
  }

  div{
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

.bg-text-right-container{
  margin: 150px;
  color: var(--main-color-light);

  h4{
    font-size: 4rem;
  }
}

.v-angled-bg-text-content {
  position: relative;
  z-index: 3;
  top: 35%;
  left: 10%;
}

.v-angled-bg-text-left, .v-angled-bg-text-right {
  position: absolute;
  z-index: 2;
  width: 50%;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.v-angled-bg-text-left {
  left: 0;
  // Additional styling for left text
}

.v-angled-bg-text-right {
  right: 0;
  // Additional styling for right text
}

.v-angled-bg-text-rotated-block {
  // Existing styles
  z-index: 1; // Ensure this is below text
}

//HORIZONTAL ANGLED BACKGROUND
.h-angled-bg-container{
  z-index: -2;
  overflow: hidden;
}

.h-angled-outside-div {
	position: relative;
	height: 15vh;
}

.h-angled-bg-top-div p {
	font-family: Oswald, sans-serif;
  font-weight: bold;
	font-size: 20vh;
  color: $main-color-dark;
	line-height: 0;
	position: absolute;
	bottom: 10vh;
	left: 5vw;
	transform: rotateZ(-6deg);
}

.h-angled-bg-top-div::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	border-style: solid;
	border-color: transparent;
	border-width: 10vh 70vw;
	border-bottom-color: var($main-color-light);
	border-right-color: var($main-color-light);
}

.h-angled-inside-div{
  position: relative;
}

.h-angled-bg-middle-div {
	background: var($main-color-light);
  color: var($main-color-light);

  /*
  .middle-div-container{
    color:white;
    padding: 10rem 20rem 10rem 20rem;
    
    h3 {
      font-size: 5rem;

       @media (max-width: 768px){
        font-size: 2.5rem;
      }
    }

    @media (max-width: 768px){
      padding: 5rem 2rem 15rem 2rem;
    }
  }
  */
}

.h-angled-bg-middle-div::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
  transform: rotateY(180deg);
	border-style: solid;
	border-color: transparent;
	border-width: 10vh 70vw;
	border-bottom-color: white;
	border-left-color: white;
}

.h-angled-bg-bottom-div {
	background: white;
	height: 40vh;
}

