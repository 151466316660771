//CSS WITH SCROLL SNAP

//IMPORTS
@import '../../Styles/Shared/theme';
@import '../../Styles/Shared/utilities';

/*
---------------------------
CUSTOM CSS FOR AboutPage.tsx
---------------------------
*/

// HISTORY SECTION
.history-section{
  margin: 10rem 20rem 0rem 20rem;

  @include respond-to(large-tablet){
    margin: 10rem 5rem 0rem 5rem;
  }

  @include respond-to(mobile){
    margin: 0;
  }
}

// VALUES SECTION
.values-section {
  padding: 2rem;
  height: 95vh;
  text-align: center;

  @include respond-to(large-tablet) {
    height: 1000px;
  }

  @include respond-to(tablet) {
    grid-template-columns: 1fr; // Stack items on top of each other on tablet and mobile
    height: 1800px;
  }
}

.values-title-container h1 {
  margin-bottom: 5rem;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 2 values per row
  row-gap: 3rem;
  column-gap: 200px;
  margin: 0 400px;

  @include respond-to(large-tablet) {
    margin: 200px 100px;
  }

  @include respond-to(tablet) {
    grid-template-columns: 1fr; // Stack items on top of each other on tablet and mobile
    margin: 100px;
  }

  @include respond-to(tablet) {
    grid-template-columns: 1fr; // Stack items on top of each other on tablet and mobile
    margin: 100px 0;
  }
}

.value-item {
  font: {
    size: 1.2rem;
  }
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;

  @include respond-to(tablet) {
    grid-template-columns: 1fr; // Stack items on top of each other on tablet and mobile
    margin: 100px;
  }

  @include respond-to(mobile) {
    grid-template-columns: 1fr; // Stack items on top of each other on tablet and mobile
    margin: 50px 0;
  }
}


// AWARDS SECTION
.awards-section {
  background-color: var(--main-color-light); // Assuming a light background
  padding: 4rem 0;
  text-align: center;

  .awards-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }

  .awards-title {
    font-size: 3rem;
    color: var(--main-color-dark);
    margin-bottom: 3rem;
  }

  .awards-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;

    .award-item {
      flex-basis: calc(25% - 1rem); // 3 items per row
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
      margin: 50px;

      .award-logo {
        width: 175px; // Example fixed width
        height: 125px; // Example fixed height
        object-fit: contain; // Maintains aspect ratio of the logo
        margin-bottom: 10px; // Space between logo and text below
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: 10px;
        //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }
      .award-name{
        font: {
          size: 1.2rem
        }
        color: var(--main-color-dark);
        margin-top: 0.5rem;
      }

      .award-year {
        font: {
          size: 1.2rem;
        }
        color: var(--main-color-dark);
        margin-top: 0.5rem;
      }

      .award-desc {
        font: {
          size: 1rem;
        }
        color: var(--main-color-dark);
        margin-top: 0.5rem;
      }
    }
  }

  @include respond-to(tablet) {
    .awards-gallery {
      .award-item {
        flex-basis: calc(50% - 1rem); // 2 items per row on tablet
      }
    }
  }

  @include respond-to(mobile) {
    .awards-gallery {
      .award-item {
        flex-basis: 100%; // 1 item per row on mobile
      }
    }

    .awards-title {
      font-size: 2.5rem;
    }
  }
}



