//CSS VARIABLES

/*
    This file is used to store reusable variables. It does not contain any css styling
*/

//IMPORTS
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

//BREAKPOINTS
$desktop-breakpoint: 1920px;
$large-tablet-breakpoint: 1280px;
$tablet-breakpoint: 1024px;
$mobile-breakpoint: 768px;

// Media Query Mixin
@mixin respond-to($breakpoint) {
    @if $breakpoint == desktop {
        @media (max-width: $desktop-breakpoint) {
            @content;
        }
    } @else if $breakpoint == large-tablet {
        @media (max-width: $large-tablet-breakpoint) {
            @content;
        }
    } @else if $breakpoint == tablet {
        @media (max-width: $tablet-breakpoint) {
            @content;
        }
    } @else if $breakpoint == portrait-tablet {
        @media (max-width: $tablet-breakpoint) {
            @content;
        }
    } @else if $breakpoint == mobile {
        @media (max-width: $mobile-breakpoint) {
            @content;
        }
    }
}

//COLORS - We are using ::root because it allow dynamic change using Javascript
:root{
    --main-color-light: #FFFFFF;
    --main-color-dark: rgb(16, 26, 44);
    --secondary-color-lightblue: #36a9e0;
    --secondary-color-grey: #878786;
    --secondary-color-light-gray: #f3f3f3;
    --input-bg: hsla(60, 0%, 53%, 6.5%);
    --input-bg-hover: hsla(60, 0%, 53%, 13%);
}

