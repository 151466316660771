@import '../../Styles/Shared/utilities.scss';
@import '../../Styles/Shared/theme.scss';

.notfound-container{
    height: 750px;
    position: relative;
    display: flex;
    flex-direction: column;
    @include flex-center;
    
    h1 {
        font: {
            size: 10rem
        }
    }

    p {
        font: {
            size: 2rem
        }

    }
    
}