// IMPORTS
@import '../../Styles/Shared/theme.scss';
@import '../../Styles/Shared/utilities.scss';

/*
---------------------------
CUSTOM CSS FOR ConstructionPage.tsx
---------------------------
*/

// DUAL SECTION CSS
.single-content-section, 
.dual-content-section {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    margin: 0 3em;
    border-radius: 10px;

    .left-side, 
    .right-side {
        flex: 1;
        margin: 0 20px;

        @include respond-to(mobile){
            margin: 20px;
        }
    }

    h3 {
        font-size: 3.5rem;
        transition: all 0.3s ease;

        @include respond-to(tablet){
            font-size: 2.5rem;
        }
    }

    p {
        font-size: 1.2rem;
        transition: all 0.3s ease;

        @include respond-to(tablet){
            font-size: 1rem;
        }
    }

    @include respond-to(large-tablet){
        flex-direction: column;
        margin: 0;
        padding: 20px 10px;
    }
}

// PARALLAX BACKGROUND
.parallax-bg {
    background-attachment: fixed;
    background-size: cover;
    background-position: center;

    @include respond-to(mobile){
        height: 200px;
    }
}

// SERVICES LIST CSS
.services-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.construction-card-container {
    padding: 5rem 1rem;
    box-sizing: border-box;
    margin: 10rem 10rem;
    height: 800px;
    width: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 25px;

    @include respond-to(large-tablet){
        margin: 10rem 5rem;
        height: 850px;
        width: auto;
    }
  
    @include respond-to(mobile){
        padding: 0;
        margin: 0 0 2.5rem 0;
        height: auto;
        width: auto;
        border-radius: 0;
    }
  }
  

.services-list-item {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    font-size: 1.2em;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    }
}

// ANIMATIONS
@keyframes moveRightToLeft {
    0%, 100% {
        transform: translateX(100%);
    }
    50% {
        transform: translateX(-100%);
    }
}

@keyframes moveLeftToRight {
    0%, 100% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
}
