@import '../../../Styles/Shared/theme.scss';
@import '../../../Styles/Shared/utilities.scss';

/* IntroSection.css */
.intro-section {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
    z-index: -2;
}
  
.videoTag {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.title-container {
    margin-left: 150px; /* you can adjust this value to your needs */
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include respond-to(mobile){
      margin-left: 50px;
    }
}

.title{
    font-size: 5rem;
    font-weight: 600;

    @include respond-to(mobile){
      font-size: 3rem;
    }

}

.subtitle{
    font-size: 3rem;

    @include respond-to(mobile){
      font-size: 2rem;
    }
}

.title-container h1, .title-container h2 {
    color: var(--main-color-light); /* adjust color to your needs */
}

@media (max-width: 480px) {
  .title-container {
    margin-left: 20px;
  }
}

