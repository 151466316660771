@import "../../Styles/Shared/theme.scss";
@import "../../Styles/Shared/utilities.scss";

.footer {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    color: var(--main-color-light);
    font-size: 14px;

    &__section {
        flex: 1;
        display: flex;
        justify-content: center;

        h5 {
            margin-bottom: 10px;
            font-size: 2rem;
            font-weight: bold;
        }

        a {
            display: block;
            text-decoration: none;
            margin-bottom: 5px;
        }
    }

    &__container {
        text-align: left;
    }

    &__copyright {
        text-align: center;
        color: var(--main-color-light);
        padding-bottom: 2px;
    }
}

.footer-socials {
    a {
        margin-right: 15px;
        font-size: 1.5rem;
        color: var(--main-color-light);

        &:hover {
            color: var(--secondary-color-lightblue);
        }
    }
}

.custom-hr {
    color: var(--main-color-light);
    height: 1px;
    margin: 2rem;
    background-color: white;
    border-radius: 10px;
    opacity: 0.6;
}

.nav-link.footer-link-custom-color {
    color: var(--main-color-light);
    font-size: 1.5rem;

    &:hover {
        color: var(--secondary-color-lightblue);
    }
}

@include respond-to(tablet) { 
    .footer {
        flex-direction: column;
        text-align: center;
        justify-content: center;

        &__section {
            margin-bottom: 20px;
            h5 {
                font-size: 1.5rem;
            }
        }

        &__container {
            text-align: center;
        }

        .nav-link.nav-link-custom-color {
            font-size: 1.2rem;
        }
    }
}
