@import '../../../Styles/Shared/utilities.scss';
@import '../../../Styles/Shared/theme.scss';

/*
---------------------------
CUSTOM CSS FOR LoadingButton.tsx
---------------------------
*/

// PARAMETERS
$gray: #bbbbbb;
* {
  font-family: 'Roboto', sans-serif;
}
.loading-button-container {
  position: relative;
  top:50%;
  left:50%;
  margin-left: -65px;
  margin-top: -20px;
  width: 130px;
  height: 40px;
  text-align: center;
}
.loading-button {
  outline:none;
  height: 40px;
  text-align: center;
  width: 130px;
  border-radius:40px;
  background: #fff;
  border: 2px solid var(--main-color-dark);
  color:var(--main-color-dark);
  letter-spacing:1px;
  text-shadow:0;
  font: {
    size:12px;
    weight:bold;
  }
  cursor: pointer;
  transition: all 0.25s ease;
  &:hover {
    color:white;
    background: var(--main-color-dark);
  }
  &:active {
    //letter-spacing: 2px;
    letter-spacing: 2px ;
  }
  &:after {
    content:"";
  }
}
.onclic {
  width: 40px;
  border-color:$gray;
  border-width:3px;
  font-size:0;
  border-left-color:var(--main-color-dark);
  animation: rotating 2s 0.25s linear infinite;

  &:after {
    content:"";
  }
  &:hover {
    color:var(--main-color-dark);
    background: white;
  }
}
.validate {
  font-size:13px;
  color: white;
  background: var(--main-color-dark);
  &:after {
    font-family:'FontAwesome';
    content:"";
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

