// IMPORTS
@import '../../Styles/Shared/theme.scss';
@import '../../Styles/Shared/utilities.scss';

.terms-container {
    margin: 0px 25px;

    h2{
        margin-bottom: 25px;
        text-decoration: solid 2px black;
        font-size: 3rem;
    }

    @include respond-to(mobile)
    {
    }
}