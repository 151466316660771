@import '../../Styles/Shared/utilities.scss';
@import '../../Styles/Shared/theme.scss';

.location-page {
    position: relative;
    overflow: auto;
    height: 100vh;
    width: 100%;
}

.map-section-container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
    animation: slide-fade 2s;
    z-index: 2;
    padding: 0 5vw;
}

.map-section-info{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    max-width: 1250px;
}

.map-section-text{
    text-align: left;
    margin-bottom: 2em;
}

.map-section-text h1 {
    position: relative;
    margin-bottom: 25px;
    font-size: 3rem;
}

.map-section-text h5 {
    font-size: 1.5rem;
}

.map-section-text h1::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--secondaryColor);
    bottom: -5px;
    left: 0;
}

.map-container{
    width: 50vw;
    height: 75vh;
    box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.2);
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
}

.map-container:hover{
    transform: scale(1.1);
}

.contact-text{
    font-size: 1.2rem;
}

@media (max-width: 1024px) {
    .map-section-container{
        flex-direction: column;
        justify-content: center;
        padding-top: 30px;
        padding-bottom: 30px;
        height: auto; /* Make the height automatic on mobile */
    }

    .contact-text{
        font-size: 1rem;
    }

    .map-section-info, .map-section-text, .map-section-content {
        width: 100%;
    }

    .location-page {
        position: relative;
        overflow: auto;
        height: auto;
        width: auto;
    }

    .map-section-text h1 {
        margin-bottom: 0px;
    }
    
    .map-container{
        width: 100%; /* Map takes the full width on mobile */
        height: 50vh; /* Adjust height as necessary */
    }
}

@keyframes slide-fade {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
