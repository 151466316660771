// IMPORTS
@import '../../Styles/Shared/theme.scss';
@import '../../Styles/Shared/utilities.scss';

.contact{
    width: 100%;
    overflow: hidden;
}

.contact .contact-container {
    height: 100vh;
    min-height: 700px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    @include respond-to(large-tablet){
        height: 75vh;
    }
}

.contact .contact-container > div{
    grid-column: 1/2;
    grid-row: 1/2;
}

.contact-column-left {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1.5rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    position: relative;
    z-index: 3;
}

.contact-heading h1{
    font-weight: 600;
    color: var(--main-color-dark);
    font-size: 3.5rem;
    line-height: 0.9;
    white-space: nowrap;
    
}

.contact-heading h1 span{
    color: var(--secondary-color-lightblue)
    
}

.contact-form-icon{
    margin-left: 2px;
}

.icon-text .contact-detail {
    margin-left: 8px; /* Space between icon and text */
}

.text {
    color: var(--main-color-dark);
    line-height: 1.1;
    font-size: 1.3rem;
    font-weight: 500;
}

.text a {
    color: var(--secondary-color-lightblue)

}

.contact-form{
    display: grid;
    margin-top: 2.55rem;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 1rem;
}

.input-wrap{
    position: relative;
}

.input-wrap.w-100{
    grid-column: span 2;
}

.form-wrapper{
    max-width: 32rem;
}

.contact-input {
    width: 100%;
    background-color: var(--input-bg);
    padding: 1.5rem 1.35rem calc(0.75rem - 2px) 1.35rem;
    border: none;
    outline: none;
    font-family: inherit;
    border-radius: 20px;
    color: var(--main-color-dark);
    font-weight: 600;
    font-size: 0.95rem;
    border: 2px solid transparent;
    box-shadow: 0 0 0 0px hsla(60, 0%, 53%, 26%);
    transition: 0.3s;
}

.contact-input:hover {
    background-color: var(--input-bg-hover);
}

.input-wrap label{
    position: absolute;
    top: 50%;
    left: calc(1.35rem + 2px);
    transform: translateY(-50%);
    color: var(--main-color-dark);
    pointer-events: none;
    transition: 0.25s;
}

.input-wrap .icon{
    position: absolute;
    right: calc(1.35rem + 2px);
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--secondary-color-grey);
    font-size: 1.25rem;
    transition: 0.3s;
}

textarea.contact-input {
    resize: none;
    width: 100%;
    min-height: 150px;
}

textarea.contact-input ~ label{
    top: 1.2rem;
    transform: none;
}

textarea.contact-input ~ label{
    top: 1.2rem;
    transform: none;
}

textarea.contact-input ~ .icon{
    top: 1.3rem;
    transform: none;
}

.input-wrap.focus .contact-input{
    background-color: var(--main-color-light);
    box-shadow: 0 0 0 2px var(--secondary-color-lightblue);
}

.input-wrap.focus label{
    color: var(--secondary-color-lightblue);
}

.input-wrap.focus .icon{
    color: var(--secondary-color-lightblue);
}

.input-wrap.not-empty label{
    font-size: 0.66rem;
    top: 0.75rem;
    transform: translateY(0);
}

.button-wrap {
    margin-top: 1.5rem;
}

.button-wrap.w-100{
    grid-column: span 2;
    text-align: center;
}

.text-warp {
    margin: 0rem 1rem;
    font-size: 0.75rem;
    position: relative
}

.text-warp.w-100 {
    grid-column: span 2;
    text-align: left;
}

.btn-submit{
    padding: 0.5rem 2rem;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    border-radius: 20px;
    background-color: var(--main-color-dark);
    color: var(--main-color-light);
    transition: 0.5s;
}

.btn-submit:hover{
    scale: 1.1;
    background-color: var(--secondary-color-lightblue);
}

.contact-column-right{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    overflow: hidden;
    pointer-events: none;
}

.image-wrapper{
    position: relative;
    grid-column: 2;
    overflow: hidden;
}

.video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 100%);
}

.image-wrapper .vid{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 1280px)
{
    .contact-heading h1
    {
        font-size: 2.5rem;
        margin-bottom: 1rem;
        white-space: normal;
    }

    .image-wrapper .vid{
        left: 10%;
    }

    .video-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 1) 90%);
    }

    .text{
        font-size: 0.9rem
    }

    .contact-form {
        display: grid;
        margin-top: 1.9rem;
        column-gap: 0.8rem;
        row-gap: 0.65rem;
    }

    .contact-input{
        border-radius: 17px;
        font-size: 0.87rem;
        padding: 1.5rem 1.2rem calc(0.75rem - 2px) 1.2rem;
    }

    .input-wrap label{
        font-size: .91rem;
        left: calc(1.2rem + 2px);
    }

    .input-wrap .icon{
        font-size: 1.1rem;
        right: calc(1.2rem + 2px);
    }

    textarea.contact-input ~ .icon{
        top: 1.2rem;
    }

    textarea.contact-input ~ label{
        top: 1.33rem;
    }

    .input-wrap.focus .contact-input{
        box-shadow: 0 0 0 1.4px var(--secondary-color-lightblue);
    }

    
    .input-wrap.not-empty label{
        font-size: 0.61rem;
    }
}

@media (max-width: 1024px)
{
    .contact-column-left{
        padding: 50px 1.5rem 0;
        grid-template-columns: 1fr;
        justify-items: center;
    }

    .contact-column-right{
        display: none;
    }
    .form-wrapper {
        width: 100%;
        padding: 1rem 0;
    }

}

@media (max-width: 440px)
{
    .input-wrap{
        grid-column: span 2;
    }

    .contact-heading h1
    {
        font-size: 1.9rem;
        line-height: 1;
        white-space: normal;
    }
    
}

