//IMPORTS
@import '../../Styles/Shared/theme.scss';
@import '../../Styles/Shared/utilities.scss';

/*
---------------------------
CUSTOM CSS FOR ManagementPage.tsx
---------------------------
*/

.management-card-container{
    box-sizing: border-box;
    margin: 5rem 20rem;
    height: auto;
    width: auto;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 25px;

    @include respond-to(large-tablet){
        margin: 10rem 5rem;
        width: auto;
    }
  
    @include respond-to(mobile){
        padding: 0;
        margin: 0 0 0 0;
        height: auto;
        width: auto;
        border-radius: 0;
    }
}

.management-card-container-dark-bg{
    background-color: var(--main-color-dark);
}

.management-card-container-light-bg{
    background-color: var(--secondary-color-light-gray);

    @include respond-to(mobile){
        background-color: var(--main-color-light);
    }
}

