@import '../../../Styles/Shared/theme.scss';  // Replace 'path_to_theme' with the actual path to theme.scss

$handheld-section-height: 500px;

// MAIN CONTAINER
.text-section-container {
  margin: 100px 0 100px 0;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  

  @include respond-to(tablet) {
    margin: 0;
    align-items: start;
    justify-content: start;
  }
}

// TEXT AND TITLE CONTAINER
.div-container-desc {
  padding: 0 3rem;
  box-sizing: border-box;
  height: 100%;
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include respond-to(tablet) {
    flex: 0;
    padding: 3rem 5rem;
    width: 100%;
  }

  @include respond-to(mobile) {
    padding: 3rem;
  }
}

// TITLE AND TEXT
.desc-title {
  text-align: left;
  position: relative;
  font-size: 3rem;

  @include respond-to(large-tablet) {
    font-size: 2rem;
  }

  @include respond-to(mobile) {
    font-size: 1.8rem;
  }
}

.text-desc {
  margin-top: 2rem;
  text-align: justify;
  font-size: 1.4rem;

  @include respond-to(large-tablet) {
    font-size: 1rem;
  }

  @include respond-to(tablet) {
    font-size: 1rem;
  }
}

.desc-title:after {
  content: '';
  position: absolute;
  bottom: -5px; 
  left: 0;
  width: 100%; 
  height: 2px; 
  background: var(--secondary-color-lightblue);
  transform: skewX(-20deg);
}

//IMAGE CONTAINER AND IMAGE STYLING
.image-container {
  flex: 1 1 50%;
  height: 100%; 

  @include respond-to(tablet) {
    flex: 0;
    width: 100%;
    height: $handheld-section-height;
  }

  &.background, &.half {
    img {
      @include respond-to(tablet) {
        width: 100%;
        height: inherit;
        object-fit: cover;
      }
    }
  }

  &.background {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @include respond-to(tablet) {
      min-height: $handheld-section-height;
    }
  }

  &.child {
    padding: 2rem;

    img {
      @include respond-to(tablet) {
        min-height: 436px;
      }

    }

    img {
      @include respond-to(mobile) {
        min-height: 350px;
      }

    }

    @include respond-to(mobile) {
      padding: 0rem 1rem 3rem 1rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
  }
}

