/*
    PRECISION PADDING (px)
    UP TO 50 px
*/

// Horizontal and vertical padding
@mixin p-padding-0{
    padding: 0
}

.p-padding-0 {
    @include p-padding-0;
}

@mixin p-padding-10{
    padding: 10px
}

.p-padding-10 {
    @include p-padding-10;
}

@mixin p-padding-20{
    padding: 20px
}

.p-padding-20 {
    @include p-padding-20;
}

@mixin p-padding-30{
    padding: 30px
}

.p-padding-30 {
    @include p-padding-30;
}

@mixin p-padding-40{
    padding: 40px
}

.p-padding-40 {
    @include p-padding-40;
}

@mixin p-padding-50{
    padding: 50px
}

.p-padding-50 {
    @include p-padding-50;
}

// Horizontal padding
@mixin p-padding-x-0{
    padding-right: 0;
    padding-left: 0
}

@mixin p-padding-x-10{
    padding-right: 10px;
    padding-left: 10px
}

@mixin p-padding-x-20{
    padding-right: 20px;
    padding-left: 20px
}

@mixin p-padding-x-30{
    padding-right: 30px;
    padding-left: 30px
}

@mixin p-padding-x-40{
    padding-right: 40px;
    padding-left: 40px
}

@mixin p-padding-x-50{
    padding-right: 50px;
    padding-left: 50px
}

// Vertical padding
@mixin p-padding-y-0{
    padding-top: 0;
    padding-bottom: 0
}

@mixin p-padding-y-10{
    padding-top: 10px;
    padding-bottom: 10px
}

@mixin p-padding-y-20{
    padding-top: 20px;
    padding-bottom: 20px
}

@mixin p-padding-y-30{
    padding-top: 30px;
    padding-bottom: 30px
}

@mixin p-padding-y-40{
    padding-top: 40px;
    padding-bottom: 40px
}

@mixin p-padding-y-50{
    padding-top: 50px;
    padding-bottom: 50px
}

/*
    PADDING (REM)
    UP TO 20 rem
*/

// padding-bottom
@mixin padding-bottom-0{
    padding-bottom: 0rem
}

@mixin padding-bottom-20{
    padding-bottom: 20rem
}

@mixin padding-bottom-15{
    padding-bottom: 15rem
}

@mixin padding-bottom-10{
    padding-bottom: 10rem
}

@mixin padding-bottom-5{
    padding-bottom: 5rem
}

// padding-top
@mixin padding-top-0{
    padding-top: 0rem
}

@mixin padding-top-5{
    padding-top: 5rem
}

@mixin padding-top-10{
    padding-top: 10rem
}

@mixin padding-top-15{
    padding-top: 15rem
}

@mixin padding-top-20{
    padding-top: 20rem
}

// padding-left
@mixin padding-left-5{
    padding-left: 0rem
}

@mixin padding-left-5{
    padding-left: 5rem
}

@mixin padding-left-10{
    padding-left: 10rem
}

@mixin padding-left-15{
    padding-left: 15rem
}

@mixin padding-left-20{
    padding-left: 20rem
}

// padding-right
@mixin padding-right-0{
    padding-right: 0rem
}

@mixin padding-right-5{
    padding-right: 5rem
}

@mixin padding-right-10{
    padding-right: 10rem
}

@mixin padding-right-15{
    padding-right: 15rem
}

@mixin padding-right-20{
    padding-right: 20rem
}

//Vertical and horizontal padding
@mixin padding-0 {
    padding: 0rem;
}

@mixin padding-5 {
    padding: 5rem;
}

@mixin padding-10 {
    padding: 10rem;
}

@mixin padding-15 {
    padding: 15rem;
}

@mixin padding-20 {
    padding: 20rem;
}

//Vertical padding (up and down)
@mixin padding-y-0{
    padding-top: 0rem;
    padding-bottom: 0rem;
}

@mixin padding-y-5{
    padding-top: 5rem;
    padding-bottom: 5rem;
}

@mixin padding-y-10{
    padding-top: 10rem;
    padding-bottom: 10rem; 
}

@mixin padding-y-15{
    padding-top: 15rem;
    padding-bottom: 15rem;
}

@mixin padding-y-20{
    padding-top: 20rem;
    padding-bottom: 20rem;
}

//Horizontal padding (left and right)
@mixin padding-x-0{
    padding-left: 0rem;
    padding-right: 0rem;
}

@mixin padding-x-5{
    padding-left: 5rem;
    padding-right: 5rem;
}

@mixin padding-x-10{
    padding-left: 10rem;
    padding-right: 10rem; 
}

@mixin padding-x-15{
    padding-left: 15rem;
    padding-right: 15rem;
}

@mixin padding-x-20{
    padding-left: 20rem;
    padding-right: 20rem;
}

////
/// 
/// 
/// 
/// 
/// 
/// 

/*
    PRECISION MARGIN (px)
    UP TO 50 px
*/

// Horizontal and vertical margin
@mixin p-margin-0{
    margin: 0
}

@mixin p-margin-10{
    margin: 10px
}

@mixin p-margin-20{
    margin: 20px
}

@mixin p-margin-30{
    margin: 30px
}

@mixin p-margin-40{
    margin: 40px
}

@mixin p-margin-50{
    margin: 50px
}

// Horizontal margin
@mixin p-margin-x-0{
    margin-right: 0;
    margin-left: 0
}

@mixin p-margin-x-10{
    margin-right: 10px;
    margin-left: 10px
}

@mixin p-margin-x-20{
    margin-right: 20px;
    margin-left: 20px
}

@mixin p-margin-x-30{
    margin-right: 30px;
    margin-left: 30px
}

@mixin p-margin-x-40{
    margin-right: 40px;
    margin-left: 40px
}

@mixin p-margin-x-50{
    margin-right: 50px;
    margin-left: 50px
}

// Vertical margin
@mixin p-margin-y-0{
    margin-top: 0;
    margin-bottom: 0
}

@mixin p-margin-y-10{
    margin-top: 10px;
    margin-bottom: 10px
}

@mixin p-margin-y-20{
    margin-top: 20px;
    margin-bottom: 20px
}

@mixin p-margin-y-30{
    margin-top: 30px;
    margin-bottom: 30px
}

@mixin p-margin-y-40{
    margin-top: 40px;
    margin-bottom: 40px
}

@mixin p-margin-y-50{
    margin-top: 50px;
    margin-bottom: 50px
}

/*
    margin (REM)
    UP TO 20 rem
*/

// margin-bottom
@mixin margin-bottom-0{
    margin-bottom: 0rem
}

@mixin margin-bottom-20{
    margin-bottom: 20rem
}

@mixin margin-bottom-15{
    margin-bottom: 15rem
}

@mixin margin-bottom-10{
    margin-bottom: 10rem
}

@mixin margin-bottom-5{
    margin-bottom: 5rem
}

// margin-top
@mixin margin-top-0{
    margin-top: 0rem
}

@mixin margin-top-5{
    margin-top: 5rem
}

@mixin margin-top-10{
    margin-top: 10rem
}

@mixin margin-top-15{
    margin-top: 15rem
}

@mixin margin-top-20{
    margin-top: 20rem
}

// margin-left
@mixin margin-left-5{
    margin-left: 0rem
}

@mixin margin-left-5{
    margin-left: 5rem
}

@mixin margin-left-10{
    margin-left: 10rem
}

@mixin margin-left-15{
    margin-left: 15rem
}

@mixin margin-left-20{
    margin-left: 20rem
}

// margin-right
@mixin margin-right-0{
    margin-right: 0rem
}

@mixin margin-right-5{
    margin-right: 5rem
}

@mixin margin-right-10{
    margin-right: 10rem
}

@mixin margin-right-15{
    margin-right: 15rem
}

@mixin margin-right-20{
    margin-right: 20rem
}

//Vertical and horizontal margin
@mixin margin-0 {
    margin: 0rem;
}

@mixin margin-5 {
    margin: 5rem;
}

@mixin margin-10 {
    margin: 10rem;
}

@mixin margin-15 {
    margin: 15rem;
}

@mixin margin-20 {
    margin: 20rem;
}

//Vertical margin (up and down)
@mixin margin-y-0{
    margin-top: 0rem;
    margin-bottom: 0rem;
}

@mixin margin-y-5{
    margin-top: 5rem;
    margin-bottom: 5rem;
}

@mixin margin-y-10{
    margin-top: 10rem;
    margin-bottom: 10rem; 
}

@mixin margin-y-15{
    margin-top: 15rem;
    margin-bottom: 15rem;
}

@mixin margin-y-20{
    margin-top: 20rem;
    margin-bottom: 20rem;
}

//Horizontal margin (left and right)
@mixin margin-x-0{
    margin-left: 0rem;
    margin-right: 0rem;
}

@mixin margin-x-5{
    margin-left: 5rem;
    margin-right: 5rem;
}

@mixin margin-x-10{
    margin-left: 10rem;
    margin-right: 10rem; 
}

@mixin margin-x-15{
    margin-left: 15rem;
    margin-right: 15rem;
}

@mixin margin-x-20{
    margin-left: 20rem;
    margin-right: 20rem;
}

/*
    HEIGHT
    UP TO 1000px
*/
@mixin height-0{
    height: 0
}

@mixin height-200{
    height: 200px;
}

@mixin height-400{
    height: 400px;
}

@mixin height-600{
    height: 600px;
}

@mixin height-800{
    height: 800px;
}

@mixin height-1000{
    height: 1000px;
}

/*
    WIDTH 
    UP TO 1000px
*/
@mixin width-200{
    width: 200px;
}

@mixin width-400{
    width: 400px;
}

@mixin width-600{
    width: 600px;
}

@mixin width-800{
    width: 800px;
}

@mixin width-1000{
    width: 1000px;
}

//FLEX CENTER
@mixin flex-center {
    justify-content: center;
    align-items: center;
}

.flex-center{
    @include flex-center;
}

/*
    POSITION
    (RELATIVE TO ABSOLUTE)
*/
@mixin pos-rel{
    position: relative
}

@mixin pos-abs{
    position: absolute
}

// TEXT POSITION

@mixin text-justify{
    text-align: justify;
}

.justify-text{
    @include text-justify;
}

.center-text{
    text-align: center;
}

.left-text{
    text-align: left;
}

.right-text{
    text-align: left;
}





