@import '../../Styles/Shared/theme.scss';

.custom-nav {
    width: 100%;
    top: 0;
    font: {
        size: 1.5rem;
        weight: 500;
    }
    letter-spacing: 0.8px;
    background-color: transparent;
    color: var(--main-color-light);
    z-index: 1000;

    @include respond-to(large-tablet) {
        font-size: 1rem;
    }

    @include respond-to(tablet) {
        font-size: 1rem;
    }

    @include respond-to(mobile) {
        background-color: var(--main-color-light);
        color: var(--main-color-dark);
        font-size: 1.25rem;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    }
}

.img-logo {
    height: 100px;
    margin-right: 2rem;

    @include respond-to(tablet){
        height: 50px;
        margin-right: 0.25rem;
    }

    @include respond-to(tablet){
        height: 100px;
        margin-right: 2rem;
    }
}

.navbar.sticky-nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.contact-button {
    width: 250px;
    padding: 7.5px;
    letter-spacing: 0.8px;
    border: 0px;
    border-radius: 5px;
    background: linear-gradient(to right, var(--main-color-light) 50%, var(--secondary-color-lightblue) 50%);
    background-size: 200% 100%;
    transition: background-position 0.5s ease;

    &:hover {
        background-position: -100% 0;
        color: #FFFFFF;
    }

    @include respond-to(large-tablet) {
        width: 200px;
    }
}

.contact-button-transparent {
    width: 250px;
    padding: 7.5px;
    letter-spacing: 0.8px;
    color: var(--main-color-light);
    font: {
        weight: 500;

    }
    border: 2px solid;
    border-color: var(--main-color-light);
    border-radius: 5px;
    background: linear-gradient(to right, rgba(0,0,0,0) 50%, var(--main-color-dark) 50%);
    background-size: 200% 100%;
    transition: background-position 0.5s ease, border-color 0.5s ease;

    &:hover {
        background-position: -100% 0;
        color: var(--main-color-light);
        border: 2px solid var(--main-color-dark);
    }

    @include respond-to(large-tablet) {
        width: 200px;
    }
}

.language-dropdown {
    margin-left: 2%;
    font-size: 1.5rem;

    &:hover {
        color: var(--secondary-color-lightblue);
        transform: scale(1.0);
    }

    @include respond-to(large-tablet) {
        font-size: 1.25rem;
    }

    @include respond-to(tablet) {
        font-size: 1rem;
    }
}

.navbar-item {
    display: block;
    position: relative;
    padding: 0.5rem;
    color: var(--main-color-light);
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out, transform 0.2s ease-in-out;

    &:hover {
        color: var(--secondary-color-lightblue);
        transform: scale(1.1);
    }

    @include respond-to(tablet) {

        &:hover {
            color: var(--secondary-color-lightblue);
            transform: none;
        }
    }

    @include respond-to(mobile) {
        color: var(--main-color-dark);
    }
}



.active-nav-item {
    text-decoration: underline;
    text-decoration-color: var(--secondary-color-lightblue);

    @include respond-to(mobile) {
        color: var(--main-color-dark);
    }
}

.nav-link.nav-link-custom-color {
    @include respond-to(tablet) {
        &:hover {
            color: var(--secondary-color-lightblue);
        }
    }

    @include respond-to(mobile) {
        &:hover {
            color: var(--secondary-color-lightblue);
        }
    }
}

