@import '../../Styles/Shared/theme.scss';
@import '../../Styles/Shared/utilities.scss';

.slider-parent {
    width: 100vw;
    height: 100vh;

    @include respond-to(mobile) {
        width: 100%;
        height: 100vh;
    }
}

.side-margins { padding: 20px; }

.card-full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    overflow: auto;
}

.logo-container{
    width: 100%;
    text-align: center;
    height: auto;
    padding: 2rem 0rem;
    background-color: var(--main-color-dark);
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.1);
}

.company-logo { 
    width: 200px; 
    height: 200px;

    @include respond-to(tablet){
        width: 150px; 
        height: 150px;
    }
}

.project-title-container, .project-header-container {
    margin: 3rem 0;
    text-align: center;
}

.project-info-container {
    align-items: center;
    display: flex;
    justify-content: space-between;

    @include respond-to(mobile) {
        flex-direction: column;
    }
}

.project-header-container { width: 75%; }

.project-title{
    font: {
        size: 5rem;
    }
    letter-spacing: 0.5rem;

    @include respond-to(tablet) {
        font-size: 3rem;
        letter-spacing: 0.3rem;
    }

    @include respond-to(mobile) {
        font-size: 2rem;
        letter-spacing: 0.2rem;
    }
}


.detail-title {
    letter-spacing: 0.5rem;
    font: {
        size: 2rem;
    }

    @include respond-to(tablet) {
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @include respond-to(mobile) {
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
    }
}


.detail {
    letter-spacing: 0.5rem;
    font: {
        size: 2rem;
    }

    @include respond-to(tablet) {
        font-size: 1.5rem;
        letter-spacing: 0.3rem;
    }

    @include respond-to(mobile) {
        font-size: 1rem;
        letter-spacing: 0.2rem;
    }
}

.project-info-item {
    text-align: center;

    @include respond-to(mobile) {
        margin: 1.2rem 0;
    }
}

.close-button-container {
    position: sticky;
}

.close-button {
    position: fixed;
    top: 20px;
    right: 20px;
    color: var(--main-color-light);
    border: none;
    background: none;
    font-size: 3em;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
    z-index: 10000;

    &:hover {
        transform: scale(1.2);
        color: var(--secondary-color-lightblue);
    }
}

.project-description-container {
    max-height: auto;
    color: var(--main-color-dark);
    max-width: 100%;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    margin: 5rem 20rem;

    h3 { 
        font-size: 3.5rem; 
        margin-bottom: 2rem; 

        @include respond-to(mobile){
            font-size: 2.5rem;
        }
    }

    h4 {
        font-size: 2.5rem;
        margin: 1rem 0;

        @include respond-to(tablet) 
        { 
            text-align: center; 
        }

        @include respond-to(mobile) { 
            font-size: 1.5rem; 
        }
    }

    p {
        font: {
            size: 1.5rem;
        }
         @include respond-to(mobile){
            font: {
                size: 1rem;
            }
         }
    }

    @include respond-to(large-tablet) {
        padding: 1em;
        margin: 5em 5rem;
    }

    @include respond-to(tablet) {
        padding: 1em;
        margin: 5em 2rem;
    }

    @include respond-to(mobile) {
        margin: 50px 0;
        padding: 20px;
    }
}

.features-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: left;
}

.features-list-item {
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    font-size: 1.5em;
}

.detailed-view-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.remove-styling {
    color: white;
    text-decoration: none;

    &:hover {
        color: white;
    }

}