.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    /* Add these lines */
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .shadow {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2); /* You can adjust the shadow properties as needed */
  }
